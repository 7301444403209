import React from 'react'
import OpenModal from 'src/components/OpenModal'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import { ContentHeader } from './style'

const Hero = ({ sendDatalayerEvent, setDobra }: DataLayerProps) => {
  return (
    <ContentHeader
      className='py-5 bg-black d-flex align-items-end align-items-md-center'
      role='img'
      aria-label='Homem segurando smartphone na mão'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-md-start'>
          <div className='col-12 col-md-6'>
            <h1 className='fs-48 lh-52 fs-md-56 lh-md-61 fs-xl-104 lh-xl-114 fw-700 text-white'>
              Inter Black
            </h1>
            <p className='fs-24 lh-28 fs-xl-48 lh-xl-52 fw-500 text-white mt-3'>
              <span className='d-xl-block'>Exclusividade que faz</span> você acontecer
            </p>
            <OpenModal
              to='modal-open-inter-black-contact-form'
              className='d-flex justify-content-md-center justify-content-lg-start'
            >
              <button
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3'
                onClick={() => {
                  setDobra && setDobra('dobra_01')
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Exclusividade que faz você acontecer',
                    element_action: 'click button',
                    element_name: 'Quero ser Inter Black',
                  })
                }}
              >
                Quero ser Inter Black
              </button>
            </OpenModal>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
