import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  min-height: 640px;
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/black-hero-360/image.webp') no-repeat ;
  background-size: cover;

  p {
    font-family: "Citrina", Helvetica, sans-serif;
  }

  @media ${device.tablet} {
    min-height: 515px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/black-hero-768/image.webp') no-repeat ;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    min-height: 576px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/black-hero-1024/image.webp') no-repeat ;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    min-height: 712px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-inter-black-reb/image.webp') no-repeat ;
    background-size: cover;
  }

  button {
    width: 100%;
    font-family: "Sora", Helvetica, sans-serif;

    &:hover {
      opacity: 0.9;
      color: ${grayscale[200]};
    }
  }
`
