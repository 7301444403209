import React from 'react'
import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'

const Faq = () => {
  return (
    <section className='py-5 bg-grayscale--500'>
      <div className='container'>
        <div className='row px-md-3'>
          <div className='col-12'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-40 fs-xl-40 lh-xl-50 text-white fw-600'>
              Perguntas frequentes
            </h3>
          </div>
          <FAQ
            id='inter-black'
            columns={{ lg: 1, xl: 1 }}
            answerData={pageContext.structuredData.faq}
            questionColor='text-white'
            answersColor='text-white'
            borderColor='#fff'
            searchBg='#6a6c72'
            placeholderColor='#f6f6fa'
            chevronColor='#FF7A00'
          />
        </div>
      </div>
    </section>
  )
}

export default Faq
