import { breakpoints } from 'src/styles/breakpoints'
import { brand, grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background: ${brand.grafite};

  img {
    width: 100%;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: ${brand.primary};
  color: ${white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  margin-top: 32px;

  &:hover {
    opacity: 0.9;
    color: ${grayscale[200]};
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 377px;
  }
`
