import { useLocation } from '@reach/router'
import queryString from 'query-string'
import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Modal } from 'src/components/Modal'
import InterBlackContactForm from 'src/components/UI/Forms/InterBlackContactForm'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'

import AExperienciaCerta from './sections/a-experiencia-certa/_index'
import AproveiteOMelhor from './sections/aproveite-o-melhor/_index'
import Beneficios from './sections/beneficios/_index'
import CartaoMastercardBlack from './sections/cartao-mastercard-black/_index'
import ComoSerClienteInterBlack from './sections/como-ser-inter-black/_index'
import Faq from './sections/faq/_index'
import Hero from './sections/hero/_index'
import QuerSaberMaisSobre from './sections/quer-saber-mais-sobre/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

function InterBlack () {
  const location = useLocation()
  const [ sendDatalayerEvent ] = useDataLayer()
  const queryParams = queryString.parse(location.search)
   const [ openModal, setOpenModal ] = useState(false)
  const [ dobra, setDobra ] = useState('dobra_1')
  const domReady = useDomReady()

  const modalOpen = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <InterBlackContactForm closeModal={() => setOpenModal(false)} dobra={dobra} customOrigin='Lp Inter Black' />
    </Modal>
  )

  return (
    <Wrapper>
      {modalOpen}
      <Layout pageContext={pageContext} modalOpenInterBlackDefaultValue={!!queryParams.fo}>
        <Hero sendDatalayerEvent={sendDatalayerEvent} setDobra={setDobra} />
        <ComoSerClienteInterBlack sendDatalayerEvent={sendDatalayerEvent} />
        <Beneficios sendDatalayerEvent={sendDatalayerEvent} />
        <AproveiteOMelhor sendDatalayerEvent={sendDatalayerEvent} />
        <CartaoMastercardBlack sendDatalayerEvent={sendDatalayerEvent} />
        <AExperienciaCerta setOpenModal={setOpenModal} setDobra={setDobra} />
        <QuerSaberMaisSobre />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default InterBlack
