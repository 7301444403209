import React, { useCallback, useState } from 'react'

import { BaseModal as Modal } from '@interco/inter-ui/components/Modal/BaseModal'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'
import beneficiosJSON from '../../assets/data/Beneficios.json'

import ImageWebp from 'src/components/ImageWebp'
import OpenModal from 'src/components/OpenModal'
import { Button, Card, ContainerWrapper, Global, Section } from './style'

type BeneficiosProps = {
  id: string;
  image: string;
  title: string;
  description: string;
  imageMd: string;
}

const ModalWidth = {
  md: 456,
  lg: 574,
  xl: 1000,
}

function setWidthByBreakpoint (width: number) {
  if (width >= widths.md && width < widths.lg) {
    return ModalWidth.md
  } else if (width >= widths.lg && width < widths.xl) {
    return ModalWidth.lg
  } else {
    return ModalWidth.xl
  }
}

const BeneficiosInterBlack = ({ sendDatalayerEvent }: DataLayerProps) => {
  const width = useWidth(300)
  const [ visible, setVisible ] = useState<boolean>(false)
  const [ modalList, setModalList ] = useState<BeneficiosProps[]>(beneficiosJSON)

  const closeModal = useCallback(() => {
    setVisible(false)
  }, [ setVisible ])

  function ordenedFirstById (id: string) {
    const objectId = beneficiosJSON.find((item: BeneficiosProps) => item.id === id) || beneficiosJSON[0]
    const newList = beneficiosJSON.filter((item: BeneficiosProps) => item.id !== id)
    newList.unshift(objectId)
    setModalList(newList)
    setVisible(true)
  }

  return (
    <>
      <Global />
      <Section className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='fs-28 lh-33 fs-md-28 lh-md-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-white fw-700 text-center'>
                Benefícios Inter Black
              </h2>
              <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white fw-400 text-center mb-4'>
                Tudo o que você pode ter
              </p>
            </div>
            {
              width < widths.md && (
                <div className='col-12'>
                  <DefaultCarousel
                    sm={{ items: 1 }}
                    md={{ items: 1 }}
                    lg={{ items: 1 }}
                    xl={{ items: 1 }}
                    customColor='orange--extra'
                    customDotColor='#fff'
                  >
                    {
                      beneficiosJSON.map((item: BeneficiosProps) => (
                        <div className='px-2' key={item.description}>
                          <ImageWebp
                            pathSrc={item.image}
                            arrayNumbers={[ ]}
                            arrayNumbersHeight={[ ]}
                            altDescription={item.title}
                          />

                          <h2 className='fs-24 lh-28 text-white fw-700 mt-3' dangerouslySetInnerHTML={{ __html: item.title }} />
                          <p className='fs-14 lh-16 text-white fw-400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                          <OpenModal
                            to='modal-open-inter-black-contact-form'
                            className='d-flex justify-content-md-center justify-content-lg-start'
                          >
                            <Button
                              onClick={() => {
                              sendDatalayerEvent({
                              section: 'dobra_03',
                              section_name: 'Benefícios Inter Black',
                              element_action: 'click button',
                              element_name: 'Quero ser Inter Black',
                            })
                              }}
                            >
                              Quero ser Inter Black
                            </Button>
                          </OpenModal>
                        </div>
                    ))
                  }
                  </DefaultCarousel>
                </div>
              )
            }
          </div>
          {
            width >= widths.md && (
              <>
                <div className='row d-flex align-items-center justify-content-center'>
                  {
                      beneficiosJSON.map((item: BeneficiosProps) => (
                        <div className='col-md-6 col-lg-4 col-xl-3' key={item.description}>
                          <Card
                            onClick={() => {
                              ordenedFirstById(item.id)
                              sendDatalayerEvent({
                                section: 'dobra_03',
                                section_name: 'Benefícios Inter Black',
                                element_action: 'click button',
                                element_name: item.title,
                              })
                            }
                            }
                          >
                            <ImageWebp
                              pathSrc={item.image}
                              arrayNumbers={[ ]}
                              arrayNumbersHeight={[ ]}
                              altDescription={item.title}
                            />
                            <div className='content'>
                              <h2 className='fs-24 lh-28 text-white fw-700' dangerouslySetInnerHTML={{ __html: item.title }} />
                            </div>
                          </Card>
                        </div>

                    ))
                  }
                  <div className='col-12 d-flex justify-content-center'>
                    <OpenModal
                      to='modal-open-inter-black-contact-form'
                      className='d-flex justify-content-md-center justify-content-lg-start'
                    >
                      <Button
                        onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_03',
                          section_name: 'Benefícios Inter Black',
                          element_action: 'click button',
                          element_name: 'Quero ser Inter Black',
                        })
                        }}
                      >
                        Quero ser Inter Black
                      </Button>
                    </OpenModal>
                  </div>
                </div>
                <Modal
                  visible={visible}
                  onClose={closeModal}
                  onClickOutside={closeModal}
                  style={{ padding: 0 }}
                  bottomFade={false}
                  width={setWidthByBreakpoint(width)}
                >
                  <ContainerWrapper>
                    <DefaultCarousel
                      sm={{ items: 1 }}
                      md={{ items: 1 }}
                      lg={{ items: 1 }}
                      xl={{ items: 1 }}
                      xxl={{ items: 1 }}
                      customColor='white'
                      customDotColor='#fff'
                    >
                      {
                        modalList.map((item: BeneficiosProps) => (
                          <div className='px-2' key={item.description}>
                            <div className='row d-flex align-items-center justify-content-between'>
                              <div className='col-12 col-xl-6 order-xl-latest'>
                                {
                                  width === widths.md ? (
                                    <ImageWebp
                                      pathSrc={item.imageMd}
                                      arrayNumbers={[ ]}
                                      arrayNumbersHeight={[ ]}
                                      altDescription={item.title}
                                    />
                                  ) : (

                                    <ImageWebp
                                      pathSrc={item.image}
                                      arrayNumbers={[ ]}
                                      arrayNumbersHeight={[ ]}
                                      altDescription={item.title}
                                    />
                                  )
                                }
                              </div>
                              <div className='col-12 col-xl-5 order-xl-first mt-3'>
                                <span className='fs-16 lh-19 fw-600 text-white mb-3'>BENEFÍCIOS DE SER CLIENTE INTER BLACK</span>
                                <h2 className='fs-24 lh-28 fs-md-28 lh-md-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-white fw-700 mt-md-4 mb-xl-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                                <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 text-white fw-400 mb-0 mt-4' dangerouslySetInnerHTML={{ __html: item.description }} />
                                <OpenModal to='modal-open-inter-black-contact-form' className='d-flex justify-content-md-center justify-content-lg-start'>
                                  <Button
                                    className='w-100'
                                    onClick={() => {
                                    sendDatalayerEvent({
                                      section: 'dobra_03',
                                      section_name: 'Benefícios Inter Black',
                                      element_action: 'click button',
                                      element_name: `'Quero ser Inter Black' - ${item.title}`,
                                    })
                                    }}
                                  >
                                    Quero ser Inter Black
                                  </Button>
                                </OpenModal>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </DefaultCarousel>
                  </ContainerWrapper>
                </Modal>
              </>
           )}
        </div>
      </Section>
    </>
  )
}

export default BeneficiosInterBlack
