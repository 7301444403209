import React from 'react'
import OpenModal from 'src/components/OpenModal'

import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import aproveiteOMelhorJson from '../../assets/data/aproveite-o-melhor.json'

import IcCashback from '@interco/icons/core/finance/ic_cashback'
import IcCurrencyExchange from '@interco/icons/core/finance/ic_currency_exchange'
import IcHouseCheck from '@interco/icons/core/finance/ic_house_check'
import IcHouseHalf from '@interco/icons/core/finance/ic_house_half'
import TrendingUp from '@interco/icons/core/finance/ic_trending_up'
import IcGlobe from '@interco/icons/core/home-objects/ic_globe'
import IcUmbrella from '@interco/icons/core/home-objects/ic_umbrella'
import { Button, Section } from './style'

type AproveiteOMelhorProps = {
  icon: string;
  description: string;
}

const AproveiteOMelhor = ({ sendDatalayerEvent }: DataLayerProps) => {
  const icon = {
    globe: <IcGlobe height={24} width={24} color='#ffffff' />,
    currencyExchange: <IcCurrencyExchange height={24} width={24} color='#ffffff' />,
    houseCheck: <IcHouseCheck height={24} width={24} color='#ffffff' />,
    houseHalf: <IcHouseHalf height={24} width={24} color='#ffffff' />,
    umbrella: <IcUmbrella height={24} width={24} color='#ffffff' />,
    cashback: <IcCashback height={24} width={24} color='#ffffff' />,
    trendingUp: <TrendingUp height={28} width={28} color='#ffffff' />,
  }

  const handleIcon = (iconName: string) => {
    switch (iconName) {
      case 'globe':
        return icon.globe
      case 'currencyExchange':
        return icon.currencyExchange
      case 'houseCheck':
        return icon.houseCheck
      case 'houseHalf':
        return icon.houseHalf
      case 'umbrella':
        return icon.umbrella
      case 'cashback':
        return icon.cashback
        case 'trendingUp':
          return icon.trendingUp
      default:
        return icon.globe
    }
  }

  return (
    <Section className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row d-flex justify-content-md-start justify-content-end'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-28 lh-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-700 text-white'>
              Aproveite o melhor com exclusividade
            </h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 fw-400 text-white mb-4'>
              Soluções pensadas pelo seu advisor sobre os serviços do Super App que mais fazem sentido para sua vida.
            </p>
            {
              aproveiteOMelhorJson.map((item: AproveiteOMelhorProps) => (
                <div className='d-flex align-items-center mb-3 mt-3' key={item.description}>
                  {handleIcon(item.icon)}
                  <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fw-400 text-white ml-3 mb-0'>{item.description}</p>
                </div>
              ))
            }
            <OpenModal to='modal-open-inter-black-contact-form' className='d-flex justify-content-md-center justify-content-lg-start'>
              <Button
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Aproveite o melhor com exclusividade',
                    element_action: 'click button',
                    element_name: 'Saber mais',
                  })
                }}
              >
                Saber mais
              </Button>
            </OpenModal>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AproveiteOMelhor
