import { breakpoints } from 'src/styles/breakpoints'
import { brand, grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color:  ${grayscale[500]} ;
  background-image:  url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra-4-360/image.webp');
  background-repeat: no-repeat;
  min-height: 930px;
  background-position: top center;

  @media (min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra-4-768/image.webp');
    background-repeat: no-repeat;
    min-height: 534px;
    background-position: right;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra-2-1024/image.webp');
    background-repeat: no-repeat;
    min-height: 607px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra-4-1440/image.webp');
    background-repeat: no-repeat;
    min-height: 724px;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: ${brand.primary};
  color: ${white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  margin-top: 32px;

  &:hover {
    opacity: 0.9;
    color: ${grayscale[200]};
  }

  @media (min-width: ${breakpoints.md}) {
    width: 360px;
  }
`
