import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const SectionSaberMaisSobre = styled.section`
  background: ${grayscale[500]};
  padding: 40px 0;
  min-height: 554px;
  align-items: center;
  display: flex;

  @media ${device.tablet} {
    min-height: 405px;
  }
  @media ${device.desktopLG} {
    min-height: 497px;
  }
  @media ${device.desktopXL} {
    min-height: 522px;
  }
`

export const BoxLearnMore = styled.div`
  background: #EBEBEB;
  padding: 24px 16px 24px 16px;
  border-radius: 16px;
  margin-bottom: 24px;
  width: 100%;
  min-height: 176px;

  &:last-child {
    margin-bottom: 0;
  }

  a, span {
    color: ${primary[500]}
  }
`
