import React, { Fragment, useEffect, useState } from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import cardJson from '../../assets/data/_a-experiencia-certa'
import { CardCarouselExperiences, RadiusBgColor, SectionExperiences } from './style'

const AExperienciaCerta = ({ setOpenModal, setDobra }: InterOneProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://intergo.app/9a5c30c1'
  const width = useWidth()

  const [ toNumber, setToNumber ] = useState<number>(0)

  useEffect(() => {
    const handleLoad = () => {
      if (width > 768) {
        setToNumber(1)
      } else if (width <= 768) {
        setToNumber(2)
      }
    }

    window.addEventListener('load', handleLoad)

    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [ width ])

  return (
    <SectionExperiences className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-28 lh-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-700 text-white text-center'>A experiência certa para você</h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 fw-400 text-white text-center'>
              Descubra os benefícios de cada perfil de relacionamento e encontre o que mais combina com você
            </p>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2, partialVisibilityGutter: 30 }}
              lg={{ items: 2, partialVisibilityGutter: 90 }}
              xl={{ items: 3 }}
              goToSlide={toNumber}
              customDotColor='#FF8700'
            >
              {
              cardJson.map((items: ExperiencesProps, index: number) => (
                <Fragment key={index}>
                  <CardCarouselExperiences image={items.image} role='img' aria-label={items.alt}>
                    <RadiusBgColor bgColor={items.radiusBgColor} />
                    <div className='content-text px-4 mx-auto'>
                      <div>
                        <h3 className='font-citrina text-graphite fs-24 lh-28 lh-md-28 fs-md-34 fs-lg-40 lh-lg-44 fw-600 mb-2'>
                          {items.title}
                        </h3>
                        <p className='text-graphite fs-16 lh-20 fs-lg-18 lh-lg-22 mb-4'>{items.description}</p>
                        {
                          items.listOfExperiences.map((list: ListOfExperiencesProps, index: number) => (
                            <div
                              key={index}
                              className='d-flex align-items-center justify-content-start mb-3'
                            >
                              <div className='mr-3 icon-adjustment'>
                                <IconsSwitch
                                  icon={list.icon}
                                  color='#3C3331'
                                  lib='interco'
                                  width='20'
                                  height='20'
                                  customPath={list.customPath}
                                />
                              </div>
                              <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 text-grayscale--500 mb-0'>
                                {list.text}
                              </p>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className='w-100 px-4 mt-4 d-flex justify-content-center'>
                      {items.link !== ''
                        ? (
                          <a
                            href={items.link}
                            title={items.textButton}
                            className='btn btn--lg text-none'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_06',
                                section_name: `A experiência certa para você - ${items.title}`,
                                element_action: 'click button',
                                element_name: items.textButton,
                                redirect_url: url,
                              })
                            }}
                          >
                            {items.textButton}
                          </a>)
                        : (
                          <button
                            title={items.textButton}
                            className='btn btn--lg text-none mt-4'
                            onClick={() => {
                              setOpenModal && setOpenModal(true)
                              setDobra('dobra_07')
                              sendDatalayerEvent({
                                section: 'dobra_07',
                                section_name: 'A experiência certa para você',
                                element_action: 'click button',
                                element_name: items.textButton,
                              })
                            }}
                          >
                            {items.textButton}
                          </button>
                        )
                      }
                    </div>
                  </CardCarouselExperiences>
                </Fragment>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionExperiences>
  )
}

export default AExperienciaCerta
