import { breakpoints } from "src/styles/breakpoints";
import { grayscale, orange, white } from "src/styles/colors";
import styled from "styled-components";

type ImageProps = {
  image?: string;
  bgColor?: string;
}

export const SectionExperiences = styled.section`
  padding: 40px 0;
  min-height: 907px;
  background: ${grayscale[500]};
  overflow: hidden;
  
  @media (min-width: ${breakpoints.md}){
    min-height: 938px;
  }
  @media (min-width: ${breakpoints.lg}){
    min-height: 1113px;
    padding-top: 64px;
  }
  @media (min-width: ${breakpoints.xl}){
    min-height: 1185px;
    padding-top: 96px;
  }

  .react-multi-carousel-list {
    overflow: visible;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(20% + 1px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc(20% + 1px)
  }
`

export const CardCarouselExperiences = styled.div`
  height: 100%;
  border-radius: 12px 16px;
  margin-right: 16px;
  background-color: #EBEBEB;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}){
    justify-content: start;
    background: #EBEBEB url(${(props: ImageProps) => props.image});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    padding-top: 160px;
    margin-right: 24px;
  }
  @media (min-width: ${breakpoints.lg}){
    padding-top: 190px;
    margin-right: 41px;
  }
  @media (min-width: ${breakpoints.xl}){
    padding-top: 180px;
  }
  @media (min-width: ${breakpoints.xxxl}){
    padding-top: 220px;
  }

  button, a {
    margin-bottom: 24px;
    background: ${orange[500]};
    color: ${white};
    max-width: 100%;

    &:hover {
      opacity: 0.9;
      color: ${grayscale[200]};
    }
  }

  .content-text {
    width: 100%;
    height: 100%;
  }

  .icon-adjustment {
    svg{
      font-size: 24px;
    }
  }
`

export const RadiusBgColor = styled.div`
  width: 100%;
  background: ${(props: ImageProps) => props.bgColor};
  height: 26px;
  border-radius: 12px 12px 0 0;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.md}){
    display: none;
  }
`
